<script setup>
import MainService from './service/mainService';
import { useProductsStore } from './stores/products.store';
import LoadingSpinner from './components/LoadingSpinner.vue';
import { ref, onMounted } from 'vue';

// Create a flag to track session initialization
const isSessionInitialized = ref(false);

// Initialize the session before anything else
async function initializeApp() {
    try {
        // Wait for session to be established
        await MainService.handleSession();
        // Then load products data
        await useProductsStore().fetchVersionAndUpdateProducts();
        // Mark initialization as complete
        isSessionInitialized.value = true;
    } catch (error) {
        console.error('Failed to initialize app:', error);
        // You may want to handle the error appropriately here
    }
}

// Start initialization immediately
initializeApp();
</script>

<template><!-- Show spinner while initializing -->
<div v-if="!isSessionInitialized" class="app-loading">
    <LoadingSpinner />
</div>

<!-- Only render the app content when session is initialized -->
<RouterView v-else />
</template>

<style scoped>
.app-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
