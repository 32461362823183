<template>
<div v-if="showLoading" class="fixed inset-0 z-50">
    <div class="absolute inset-0 bg-black bg-opacity-25 pointer-events-auto"></div>
    <div class="flex items-center justify-center h-full">
        <Spinner />
    </div>
</div>
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useProductsStore } from "@/stores/products.store";
import Spinner from "@/components/Spinner.vue";

const productsStore = useProductsStore();

const props = defineProps({
    isLoading: {
        type: Boolean,
        required: true,
    },
});
const showLoading = computed(() => productsStore.isLoading || props.isLoading);

</script>
